import "nprogress/nprogress.css";
import '../styles/globals.css'

import { ChakraProvider, Text } from '@chakra-ui/react';
import theme from '../theme'
import UserWayScript from '../scripts/UserWay'
import ChatlioScript from '../scripts/Chatlio'
import Head from 'next/head';
import { GoogleAnalytics, usePageViews } from "nextjs-google-analytics";
import { ErrorBoundary } from 'react-error-boundary'
import { Router, useRouter } from 'next/router';


/***
 * FontAwesome includes
 */
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library, config } from '@fortawesome/fontawesome-svg-core'

import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faPlane } from '@fortawesome/free-solid-svg-icons/faPlane'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons/faGlobeAmericas'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons/faGlobeAfrica'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons/faPhoneAlt'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faHandshake } from '@fortawesome/free-solid-svg-icons/faHandshake'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faMapMarked } from '@fortawesome/free-solid-svg-icons/faMapMarked'
import { faRssSquare } from '@fortawesome/free-solid-svg-icons/faRssSquare'
import { faPodcast } from '@fortawesome/free-solid-svg-icons/faPodcast'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit'
import { faMessage } from '@fortawesome/free-solid-svg-icons/faMessage'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe'
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChild } from '@fortawesome/free-solid-svg-icons/faChild'
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons/faPaintbrush'
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding'
import { faFutbol } from '@fortawesome/free-solid-svg-icons/faFutbol'
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands'
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook'
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons/faHandHoldingHeart'
import { faRoad } from '@fortawesome/free-solid-svg-icons/faRoad'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faAmbulance } from '@fortawesome/free-solid-svg-icons/faAmbulance'
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons/faMoneyBill'
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar'
import { faCloudSun } from '@fortawesome/free-solid-svg-icons/faCloudSun'
import { faLanguage } from '@fortawesome/free-solid-svg-icons/faLanguage'
import { faPrayingHands } from '@fortawesome/free-solid-svg-icons/faPrayingHands'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments'
import { faShareNodes } from '@fortawesome/free-solid-svg-icons/faShareNodes'
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import { faList } from '@fortawesome/free-solid-svg-icons/faList'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons/faExternalLink'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping'
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons/faAlignCenter'

import { faHillAvalanche } from '@fortawesome/free-solid-svg-icons/faHillAvalanche'
import { faHandshakeAngle } from '@fortawesome/free-solid-svg-icons/faHandshakeAngle'
import { faHouseCrack } from '@fortawesome/free-solid-svg-icons/faHouseCrack'
import { faHillRockslide } from '@fortawesome/free-solid-svg-icons/faHillRockslide'
import { faHouseTsunami } from '@fortawesome/free-solid-svg-icons/faHouseTsunami'
import { faVolcano } from '@fortawesome/free-solid-svg-icons/faVolcano'
import { faHouseFloodWater } from '@fortawesome/free-solid-svg-icons/faHouseFloodWater'
import { faWheatAwnCircleExclamation } from '@fortawesome/free-solid-svg-icons/faWheatAwnCircleExclamation'
import { faTemperatureFull } from '@fortawesome/free-solid-svg-icons/faTemperatureFull'
import { faSunPlantWilt } from '@fortawesome/free-solid-svg-icons/faSunPlantWilt'
import { faFire } from '@fortawesome/free-solid-svg-icons/faFire'
import { faTornado } from '@fortawesome/free-solid-svg-icons/faTornado'
import { faCloudShowersWater } from '@fortawesome/free-solid-svg-icons/faCloudShowersWater'
import { faHurricane } from '@fortawesome/free-solid-svg-icons/faHurricane'
import { faVialVirus } from '@fortawesome/free-solid-svg-icons/faVialVirus'
import { faTowerBroadcast } from '@fortawesome/free-solid-svg-icons/faTowerBroadcast'
import { faTents } from '@fortawesome/free-solid-svg-icons/faTents'
import { faBowlFood } from '@fortawesome/free-solid-svg-icons/faBowlFood'

import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar'

import {faCircleCheck } from '@fortawesome/free-regular-svg-icons/faCircleCheck'

import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'

import CookieConsent from 'react-cookie-consent';
import { colorToVar } from '../utils/misc';
import Link from 'next/link';
import { useEffect } from 'react';
import { TrackJS } from 'trackjs';
import nProgress from 'nprogress';
import { SeoUtils } from "../utils/seoUtils";

library.add(
  faCircleExclamation,
  faPlane,
  faGlobeAmericas,
  faChevronRight,
  faArrowRight,
  faCircleCheck,
  faCalendar,
  faCalendarAlt,
  faInfoCircle,
  faGlobeAfrica,
  faUserFriends,
  faPhoneAlt,
  faUser,
  faHandshake,
  faHandsHelping,
  faEnvelope,
  faMapMarked,
  faRssSquare,
  faPodcast,
  faCheck,
  faEdit,
  faMessage,
  faDownload,
  faFilePdf,
  faArrowLeft,
  faTwitter,
  faYoutube,
  faInstagram,
  faFacebook,
  faSearch,
  faAngleRight,
  faGlobe,
  faVideo,
  faCircleNotch,
  faInfo,
  faAt,
  faPhone,
  faMapMarkerAlt,
  faXmark,
  faBars,
  faChevronDown,
  faChild,
  faPaintbrush,
  faBuilding,
  faFutbol,
  faHands,
  faBook,
  faHandHoldingHeart,
  faRoad,
  faUserCircle,
  faUsers,
  faAmbulance,
  faQuestion,
  faMoneyBill,
  faChartBar,
  faGlobeAfrica,
  faCloudSun,
  faLanguage,
  faPrayingHands,
  faCircle,
  faComments,
  faShareNodes,
  faSort,
  faClock,
  faCopy,
  faList,
  faBriefcase,
  faExternalLink,
  faExternalLinkAlt,
  faAlignCenter,
  faHillAvalanche,
  faHandshakeAngle,
  faHouseCrack,
  faHillRockslide,
  faHouseTsunami,
  faVolcano,
  faHouseFloodWater,
  faWheatAwnCircleExclamation,
  faTemperatureFull,
  faSunPlantWilt,
  faFire,
  faTornado,
  faCloudShowersWater,
  faHurricane,
  faVialVirus,
  faTowerBroadcast,
  faTents,
  faBowlFood
);

config.autoAddCss = false
/* End FontAwesome includes */

function ErrorFallback({ error, resetErrorBoundary }) {
  TrackJS.track(error);
  
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

function PpmMainSiteApp({ Component, pageProps }) {
  // const TrackJS = require("../utils/trackjs-isomorphic.js").TrackJS;

  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', () => nProgress.start());
    router.events.on('routeChangeComplete', () => nProgress.done());
    router.events.on('routeChangeError', () => nProgress.done());
  })

  if (!TrackJS.isInstalled()) {
    TrackJS.install({
      token: "be9c389736bb435b945063663ea5c9b4",
      application: "PPM-Main",
      enabled: process.env.NODE_ENV !== "development",
    });
  }


  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} >
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href={SeoUtils.getCanonicalUrl(router)} />
      </Head>
      <ChakraProvider theme={theme}>
        <UserWayScript />
        <ChatlioScript />
        <GoogleAnalytics trackPageViews />
        <CookieConsent
          location="bottom"
          buttonText="I Agree"
          cookieName="acceptedCookieConsent"
          contentStyle={{
            flex: null,
            justifyContent: 'center', 
            alignItems: 'center'
          }}
          style={{ 
            background: colorToVar('brand.darkgray', 600),
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '82px'
          }}
          buttonStyle={{ 
            color: 'white',
            backgroundColor: colorToVar('brand.green', 600), 
            fontSize: "13px",
            borderRadius: '4px'
          }}
          expires={150}
        >
          <Text textAlign="center" color="white">
            We use cookies to give you the best online experience.{" "}
            Please read our <Link href="/privacy-policy" passHref legacyBehavior><Text as="a">privacy policy</Text></Link>.
          </Text>
        </CookieConsent>
        <Component {...pageProps} />
      </ChakraProvider>
    </ErrorBoundary>
  );
}

export default PpmMainSiteApp
